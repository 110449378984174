<template>
    <div>
		<ShutterPanel />
    </div>
</template>


<script type="text/javascript">
    import ShutterPanel from 'GroomyRoot/components/Utils/ShutterPanel'
    import Shutter from '@/mixins/Shutter.js'
    import ShutterMvt from '@/mixins/shutters-manager/Mouvements.js'

	export default {
		name: "historiqueInterne",
		mixins: [Shutter, ShutterMvt],
        props: ['horse_id'],
        components: {
			ShutterPanel
        },
		mounted() {
            this.init_component()
		},
		methods: {
            init_component() {
                this.openMvtInterneHorseHistory(this.horse_id)
            }
		}
	}
</script>
